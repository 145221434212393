import axios from "./base"

export default {
  config() {
    return axios.get("/config")
  },
  facebookLogin(data) {
    return axios.post("/login/facebook", data)
  },
  googleLogin(data) {
    return axios.post("/login/google", data)
  },
  appleLogin(data) {
    return axios.post("/login/apple", data)
  },
  register(data) {
    return axios.post("/signup", data)
  },
  appLogin(data) {
    return axios.post("/login", data)
  },
  getCommunications(data) {
    return axios.get("/communications", { params: data })
  },
  getCommunication(id, data) {
    return axios.get(`/communications/${id}`, { params: data })
  },
  getEvents(data) {
    return axios.get("/events", { params: data })
  },
  createEvent(data) {
    return axios.post("/events/", data)
  },
  updateEvent(id, data) {
    return axios.patch(`/events/${id}`, data)
  },
  destroyEvent(id) {
    return axios.delete(`/events/${id}`)
  },
  getEventRegistrations(data) {
    return axios.get("/event_registrations", { params: data })
  },
  getProducts(data) {
    return axios.get("/store/products", { params: data })
  },
  createProduct(data) {
    return axios.post("/store/products", data)
  },
  updateProduct(id, data) {
    return axios.patch(`/store/products/${id}`, data)
  },
  destroyProduct(id) {
    return axios.delete(`/store/products/${id}`)
  },
  getUsers(data) {
    return axios.get("/user_profiles", { params: data })
  },
  subscribeEvent(data) {
    return axios.post("/event_registrations", data)
  },
  getPromotions(data) {
    return axios.get("/promotions", { params: data })
  },
  updateUserProfile(data) {
    return axios.patch("/profile", data)
  },
  updateCompanyProfile(data) {
    return axios.patch("/company_profiles", data)
  },
  createFaq(data) {
    return axios.post("/faqs/", data)
  },
  updateFaq(id, data) {
    return axios.patch(`/faqs/${id}`, data)
  },
  destroyFaq(id) {
    return axios.delete(`/faqs/${id}`)
  },
  createCompanyService(data) {
    return axios.post("/company_services/", data)
  },
  updateCompanyService(id, data) {
    return axios.patch(`/company_services/${id}`, data)
  },
  destroyCompanyService(id) {
    return axios.delete(`/company_services/${id}`)
  },
  createPrescription(data) {
    return axios.post("/prescriptions/", data)
  },
  updatePrescription(id, data) {
    return axios.patch(`/prescriptions/${id}`, data)
  },
  destroyPrescription(id) {
    return axios.delete(`/prescriptions/${id}`)
  },
  createSpecialRequest(data) {
    return axios.post("/special_requests/", data)
  },
  createUploadSignature(data) {
    return axios.post("/upload-presigns", data)
  },
  updateSpecialRequest(id, data) {
    return axios.patch(`/special_requests/${id}`, data)
  },
  destroySpecialRequest(id) {
    return axios.delete(`/special_requests/${id}`)
  },
  addToCart(data) {
    return axios.post("/store/carts", data)
  },
  updateQuantity(line_item_id, data) {
    return axios.patch(`/store/items/${line_item_id}`, data)
  },
  removeFromCart(data) {
    return axios.delete("/store/carts", { data: data })
  },
  applyCartCoupon(data) {
    return axios.post("/store/coupon", data)
  },
  removeCoupon(data) {
    return axios.delete("/store/coupon", { data: data })
  },
  updateCheckout(data) {
    return axios.patch("/store/checkout", data)
  },
  createComment(URL, data) {
    return axios.post(URL, data)
  },
  recoverPassword(data) {
    return axios.post("/recover-password", data)
  },
  updatePassword(data) {
    return axios.post("/update-password", data)
  },
  createOrderEvent(data) {
    return axios.post("/store/order-events", data)
  },
  updateRecommended(data) {
    return axios.post("/store/recommended-products", data)
  },
  updateHighlighted(data) {
    return axios.post("/store/highlighted-products", data)
  },
  updateUnavailable(data) {
    return axios.post("/store/unavailable-products", data)
  },
  sendPush(data) {
    return axios.post("/notifications", data)
  },
  deleteAccount() {
    return axios.delete("/account")
  },
  checkPaymentStatus(cartId, id) {
    return axios.get(`/store/payment-status/${id}?cart_id=${cartId}`)
  },
  realtimeToken() {
    return axios.get("/realtime-token")
  },
  fetchNotifications() {
    return axios.get("/notifications")
  },
  removeNotification(id) {
    return axios.delete(`/notifications/${id}`)
  },
  removeAllNotifications() {
    return axios.post("/notifications/remove-all")
  },
  updateSettings(data) {
    return axios.patch("/settings", data)
  },
  applyLoyaltyPoints(data = {}) {
    return axios.post("/store/apply-points", data)
  },
  fetchPermissions() {
    return axios.get("/features")
  },
  createMeasurement(data) {
    return axios.post("/measurements", data)
  },
  destroyMeasurement(id) {
    return axios.delete(`/measurements/${id}`)
  },
  createLoyaltyWallet(data) {
    return axios.post("/loyalty-wallet", data)
  },
  updateLocale(data) {
    return axios.patch("/language", data)
  },
  getMeasurementUsers(data = {}) {
    return axios.get("/measurement-users", { params: data })
  },
  getMeasurementUser(id) {
    return axios.get(`/measurement-users/${id}`)
  },
  createMeasurementUser(data) {
    return axios.post("/measurement-users", data)
  },
  updateMeasurementUser(id, data) {
    return axios.patch(`/measurement-users/${id}`, data)
  },
  destroyMeasurementUser(id) {
    return axios.delete(`/measurement-users/${id}`)
  },
  getMeasurementCharts(data = {}) {
    return axios.get("/measurement-charts", { params: data })
  },
  fetchPolicies() {
    return axios.get("/company-policies")
  },
  fetchUserConsents(data) {
    return axios.get("/user-consents", { params: data })
  },
  createUserConsent(data) {
    return axios.post("/user-consents", data)
  },
  destroyUserConsent(id) {
    return axios.delete(`/user-consents/${id}`)
  },
}
